import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Helmet } from "react-helmet";

const Privasi = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
      <Helmet>
        <title>Kebijakan Privasi - Ethical Hacker Indonesia</title>  
        <meta name="description" content="Informasi kebijakan privasi yang ditetapkan oleh Ethical Hacker Indonesia" />
        <meta name="image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta property="og:url" content="https://hack.co.id/privasi/" />
        <meta property="og:title" content="Kebijakan Privasi" />
        <meta property="og:description" content="Informasi kebijakan privasi yang ditetapkan oleh Ethical Hacker Indonesia" />
        <meta property="og:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@EthicalHackID" />
        <meta name="twitter:title" content="Kebijakan Privasi"  />
        <meta name="twitter:description" content="Informasi kebijakan privasi yang ditetapkan oleh Ethical Hacker Indonesia" />
        <meta name="twitter:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
      </Helmet>  
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">
                    Kebijakan Privasi
                  </h2>
                  <p className="gr-text-8 mb-13">
                    Informasi kebijakan privasi yang ditetapkan oleh Ethical Hacker Indonesia
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <p className="gr-text-9">
                    Dengan mengunjungi &amp; situs ini maka Anda setuju 
                    untuk terikat kepada syarat-syarat dan ketentuan berikut ini mengenai kebijakan privasi 
                    yang ditetapkan oleh Kami. Kami mungkin mengubah syarat-syarat dan kondisi ini sewaktu-waktu, 
                    dan oleh karenanya Anda diharapkan untuk melakukan pengecekan secara berkala.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Data yang diperlukan untuk periksadata.com</h3>
                    <p className="gr-text-9">
                      <a href="https://periksadata.com" target="_blank">Periksadata.com</a> menggunakan sumber terbuka dari < a href="https://haveibeenpwned.com/" target="_blank" >HaveiBeenPwned</a>, keseluruhan data dan keterangan mengenai kebocoran data bersumber dari <a href="https://haveibeenpwned.com/API/v3" target="_blank" >API</a> milik < a href="https://haveibeenpwned.com/" target="_blank" >HaveiBeenPwned</a>. 
                      Untuk keperluan pemeriksaan kebocoran data Anda, Kami akan memerlukan data pribadi dari Anda antara lain sebagai berikut:</p>
                      <ul className="list-unstyled">
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Alamat email
                      </li>
                      
                    </ul>
                    <p className="gr-text-9">Bahwa Kami juga tidak pernah menyimpan informasi alamat email yang Anda masukkan ketika memeriksa kebocoran data.</p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Data-data atau Informasi yang diperlukan untuk laporkan penipuan</h3>
                    <p className="gr-text-9">
                      Untuk keperluan melaporkan penipuan, Kami akan memerlukan data-data pribadi dari Anda antara lain sebagai berikut:</p>
                      <ul className="list-unstyled">
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Nama Lengkap
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Email
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Nomor HP
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Informasi rekening terduga penipu yang kamu laporkan
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Rincian dan jumlah transaksi
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Bukti pembayaran
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Bukti percakapan
                      </li>
                    </ul>
                    <p className="gr-text-9">Bahwa semua informasi yang diberikan oleh Anda tersebut akan Kami simpan sebaik-baiknya dengan memperhatikan faktor keamanan.</p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Data-data atau Informasi yang diperlukan untuk pendaftaran relawan</h3>
                    <p className="gr-text-9">
                      Untuk keperluan pendaftaran relawan, Kami akan memerlukan data-data pribadi dari Anda antara lain sebagai berikut:</p>
                      <ul className="list-unstyled">
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Nama Lengkap
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Email
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Nomor HP
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Tanggal Lahir
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Alamat Tinggal Saat Ini
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Kota
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Provinsi
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Akun Telegram
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Akun Twitter
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Profesi Saat Ini
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Informasi Kontak Darurat (Nomor, Nama dan Hubungan)
                      </li>
                    </ul>
                    <p className="gr-text-9">Bahwa semua informasi yang diberikan oleh Anda tersebut akan Kami simpan sebaik-baiknya dengan memperhatikan faktor keamanan.</p>
                  </div>
                  <div className="single-block mb-13">
                    <h3 className="gr-text-8 font-weight-bold">Tujuan Pengumpulan Data</h3>
                    <p className="gr-text-9">Informasi dan data-data pribadi yang diperoleh oleh Kami sehubungan dengan pendaftaran relawan yang dilakukan oleh Anda, hanya akan digunakan antara lain untuk:</p>
                    <ul className="list-unstyled">
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Memproses aplikasi dan/atau segala bentuk pendaftaran dan/atau komunikasi yang diajukan oleh Anda;
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Melakukan komunikasi kepada Anda
                      </li>
                    </ul>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Pemberian dan Pengungkapan Informasi Kepada Pihak Lain
                    </h3>
                    <p className="gr-text-9 pb-5">
                    Bahwa kecuali untuk kepentingan pengumpulan data sebagaimana telah diungkapkan diatas, 
                    Ethical Hacker Indonesia tidak akan menjual, mengalihkan, mendistribusikan dan/atau membuka informasi dan 
                    data-data pribadi Anda kepada orang lain dan/atau Pihak Ketiga yang tidak berkepentingan.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Keamanan
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                    Ethical Hacker Indonesia berkomitmen untuk memastikan bahwa informasi yang Anda berikan kepada Ethical Hacker Indonesia dalam keadaan aman. 
                    Untuk mencegah akses tidak sah, Ethical Hacker Indonesia melakukan tindakan pengamanan fisik, elektronik, dan prosedur 
                    manajerial yang diperlukan untuk melindungi informasi Anda yang Ethical Hacker Indonesia kumpulkan secara daring.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Koneksi ke Situs Lain
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                    Untuk mempermudah penggunaan Anda, situs Ethical Hacker Indonesia mungkin menyediakan link ke situs-situs pihak ketiga lainnya. 
                    Namun, pada saat Anda mengunjungi link-link ini, Ethical Hacker Indonesia tidak bertanggung jawab atas informasi apapun yang Anda 
                    berikan kepada situs-situs tersebut. Masing-masing situs memiliki kebijakan penanganan privasi tersendiri dan 
                    Ethical Hacker Indonesia tidak bertanggung jawab atas isi dari situs mereka. Oleh karena itu, Anda harus berhati-hati dan mempelajari 
                    pernyataan kebijakan privasi yang berlaku di situs tersebut sebelum memberikan informasi Anda.</p>
                    <p className="gr-text-9 pb-5">
                    Entitas apapun yang Anda pilih sebagai kontak atau berinteraksi baik yang terdaftar dalam direktori ataupun di 
                    tempat lain selain dari situs Ethical Hacker Indonesia, adalah sepenuhnya tanggung jawab layanan tersebut pada Anda, dan Anda setuju 
                    bahwa situs Ethical Hacker Indonesia tidak dapat dikenakan tindakan apapun untuk kerusakan, kerugian atau biaya yang keluar atas 
                    interaksi yang terjadi antara Anda.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Penggunaan Layanan Pihak Ketiga
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                    Kami menggunakan Penyedia Layanan pihak ketiga untuk menerima data, memantau dan menganalisis penggunaan Layanan kami. Diantaranya :</p>
                    <p className="gr-text-9 pb-5 mb-0">
                    <b>Google Analytics</b></p>
                    <p className="gr-text-9 pb-5 mb-0">
                      Situs web ini menggunakan analytics sebagai tools analisis website, yang mencakup berbagai hal seperti pola kunjungan, usia, 
                      demografi, dan informasi lain yang didapatkan dari kunjungan anda di website ini.  Tools ini merupakan tools reporting 
                      dari Google dan telah digunakan jutaan website di seluruh dunia. Untuk informasi detil mengenai privasi dan kebijakan 
                      umum dari Google Analytics dapat mengakses link ini : https://www.google.com/intl/en/policies/privacy/</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Privasi;
